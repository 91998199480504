import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/main.scss"

const Contact = () => (
  <Layout headline="Today I learned">
    <SEO siteTitle="#TIL" />
    <article>
    <p>
      I got inspired to do #TIL by <a href="https://twitter.com/mmatuzo?lang=de" rel="noopener noreferrer" target="_blank">@mmatuzo</a>, a guy I follow on Twitter. 
      Almost every day I learn something new, and why should I keep it to myself? 
      Maybe you have the same tasks to solve I did before, and I don't see any reason you shouldn't 
      benefit from the things I already learned. I think this is a fantastic way to share knowledge. 
      As soon as you jump into a post, you will find a detailed description with related examples 
      I created with <a href="https://codepen.io/" rel="noopener noreferrer" target="_blank">codepen</a>. Feel free to share what you see.
    </p>
    <Link to="/">back home</Link>
    </article>
  </Layout>
)

export default Contact
